<template>
    <a-layout :class="['layout', device]">
        <!-- SideMenu -->
        <side-menu
            mode="inline"
            :menus="menus"
            :theme="navTheme"
            :collapsed="collapsed"
            :collapsible="true"
            v-if="!$store.state.app.isFullScreen"
        ></side-menu>
        <a-layout :collapsed="collapsed" :style="{ paddingLeft: contentPaddingLeft, height: '100vh', overflow: 'hidden' }">
            <!-- layout header -->
            <global-header
                v-if="!$store.state.app.isFullScreen"
                :menus="menus"
                :theme="navTheme"
                :collapsed="collapsed"
                :device="device"
                @toggle="toggle"
            />
            <!-- layout content -->
            <a-layout-content style="display: flex; flex-direction: column">
                <!-- 多标签 -->
                <multi-tab v-if="!$route.meta.hiddenMultiTab && $route.name != 'companyboard' && $route.name != 'hsjboard'"></multi-tab>
                <!-- 面包屑 -->
                <!-- <page-header v-if="!$route.meta.hiddenHeader && !$store.state.app.isFullScreen"></page-header> -->
                <!-- 页面主体内容 -->
                <transition name="page-transition">
                    <router-view />
                </transition>
            </a-layout-content>
            <!-- layout footer -->
            <a-layout-footer v-if="!$store.state.app.isFullScreen">
                <global-footer :theme="navTheme" :collapsed="collapsed" />
            </a-layout-footer>

            <!-- Setting Drawer  -->
            <!-- <setting-drawer v-if="!$store.state.app.isFullScreen"></setting-drawer>
            <audio ref="alertAudioEl" :src="alertAudio" style="visibility: hidden" /> -->
        </a-layout>
    </a-layout>
</template>

<script>
import { triggerWindowResizeEvent } from '@/utils/util'
import { mapState, mapActions, mapMutations } from 'vuex'
import { mixin, mixinDevice } from '@/utils/mixin'
import config from '@/config/defaultSettings'
import SideMenu from '@/components/Menu/SideMenu'
import GlobalHeader from '@/components/GlobalHeader'
import PageHeader from '@/components/PageHeader'
import RouteView from './RouteView.vue'
import GlobalFooter from '@/components/GlobalFooter'
import SettingDrawer from '@/components/SettingDrawer'
import { convertRoutes } from '@/utils/routeConvert'
import { Modal, notification } from 'ant-design-vue'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import Vue from 'vue'
import mqtt from 'mqtt'
export default {
    name: 'BasicLayout',
    mixins: [mixin, mixinDevice],
    components: {
        RouteView,
        SideMenu,
        GlobalHeader,
        PageHeader,
        GlobalFooter,
        SettingDrawer
    },
    data() {
        return {
            alertAudio: require('@/assets/sound.mp3'),
            production: config.production,
            collapsed: false,
            menus: [],
            isRefresh: true,
            mtopic: 'alarmNotice_' + process.env.VUE_APP_MQTT_TOPIC,
            sysTopic: 'roleNotice_' + process.env.VUE_APP_MQTT_TOPIC,
            client: {},
            hasMenu: false
        }
    },
    computed: {
        ...mapState({
            // 动态主路由
            mainMenu: state => state.route.addRouters,
            layout: state => state.app.layout,
            multiTab: state => state.app.multiTab
        }),
        contentPaddingLeft() {
            if (!this.fixSidebar || this.isMobile()) {
                return '0'
            }
            if (this.sidebarOpened) {
                return '202px'
            }
            return '80px'
        }
    },
    watch: {
        layout(val) {
            console.log('val', val)
        },
        sidebarOpened(val) {
            this.collapsed = !val
        },
        // 菜单变化
        mainMenu(val) {
            setTimeout(() => {
                this.setMenus()
                const paths = this.menus[0].path 
                console.log(paths, 'paths', this.hasMenu)
                if (!this.hasMenu) {
                    this.$router.push(paths)
                }
            })
        }
    },
    created() {
        this.getAreaType()
        this.setMenus()
        this.collapsed = !this.sidebarOpened
    },
    mounted() {
        let mqtt_url = location.protocol == 'https:' ? process.env.VUE_APP_MQTTs_URL : process.env.VUE_APP_MQTT_URL
        this.client = mqtt.connect(mqtt_url, {
            username: process.env.VUE_APP_MQTT_USERNAME,
            password: process.env.VUE_APP_MQTT_PASSWORD
        })
        this.SAVE_MQTT(this.client)
        this.client.on('connect', e => {
            console.log(`连接成功 ${mqtt_url}`)
            this.$store.state.app.mqtt = this.client
            // 后端反馈盒子消息订阅
            this.client.subscribe('jhx_rj_controller_' + this.$store.state.user.info.id, err => {
                if (!err) {
                    console.log('订阅成功:' + 'jhx_rj_controller_' + this.$store.state.user.info.id)
                }
            })
            // 系统权限变更订阅
            this.client.subscribe(this.sysTopic + this.$store.state.user.info.id, err => {
                if (!err) {
                    console.log('订阅成功:' + this.sysTopic + this.$store.state.user.info.id)
                }
            })
            // 订阅算法能力获取
            this.client.subscribe('/edge_app_controller_reply', err => {
                if (!err) {
                    console.log('订阅成功:/edge_app_controller_reply')
                }
            })
            // 心跳
            this.client.subscribe('mqtt_back_end_heart', err => {
                if (!err) {
                    console.log('订阅成功:mqtt_back_end_heart')
                }
            })
        })
        this.client.on('message', (topic, message) => {
            if (topic == 'mqtt_back_end_heart') {
                console.log('心跳中', this.$moment().format('YYYY-MM-DD HH:mm:ss'))
                return
            }
            console.log('msg', msg, 'topic', topic, `${this.sysTopic}${this.$store.state.user.info.id}`)
            let msg = ''
            if (topic != `${this.sysTopic}${this.$store.state.user.info.id}`) {
                msg = JSON.parse(message.toString())
            }
            /**船舶码头告警 */
            // topic.includes(this.mtopic) && this.shipAlarm(msg)
            /**后端终端通道 */
            if (topic == 'jhx_rj_controller_' + this.$store.state.user.info.id) {
                this.$root.$emit('device', msg)
            }
            /**盒子 */
            if (topic == '/edge_app_controller_reply') {
                this.$root.$emit('task', msg)
            }
            // 接受系统权限推送
            if (topic == `${this.sysTopic}${this.$store.state.user.info.id}`) {
                let secondsToGo = 5
                let refreshIcon = require('../assets/rocket.png')
                const h = this.$createElement
                const modal = this.$success({
                    title: '',
                    okText: '抢先体验',
                    class: 'permisModel',
                    width: 315,
                    content: h('div', { class: 'mymsg' }, [
                        h('img', { attrs: { src: refreshIcon } }),
                        h('p', `发现新功能， ${secondsToGo}S后自动更新~`)
                    ])
                })
                const interval = setInterval(() => {
                    secondsToGo -= 1
                    modal.update({
                        content: h('div', { class: 'mymsg' }, [
                            h('img', { attrs: { src: refreshIcon } }),
                            h('p', `发现新功能， ${secondsToGo}S后自动更新~`)
                        ]),
                        onOk: () => {
                            console.log('OK')
                            this.changeMenu()
                        }
                    })
                }, 1000)
                setTimeout(() => {
                    clearInterval(interval)
                    modal.destroy()
                    this.changeMenu()
                }, secondsToGo * 1000)
            }
        }),
            // 断开发起重连
            this.client.on('reconnect', error => {
                console.log('正在重连:', error)
            })
        // 链接异常处理
        this.client.on('error', error => {
            console.log('连接失败:', error)
        })
    },
    beforeDestroy() {
        this.client && this.client.end()
    },
    methods: {
        ...mapMutations(['SAVE_MQTT']),
        getAreaType() {},
        /**船舶码头告警 */
        changeMenu() {
            this.$store
                .dispatch('GetInfo')
                .then(res => {
                    if (res.menus.length < 1) {
                        Modal.error({
                            title: '提示：',
                            content: '无菜单权限，请联系管理员',
                            okText: '确定',
                            onOk: () => {
                                this.$store.dispatch('Logout').then(() => {
                                    window.location.reload()
                                })
                            }
                        })
                        return
                    }
                    let antDesignmenus
                    // eslint-disable-next-line camelcase
                    const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
                    // eslint-disable-next-line camelcase
                    if (all_app_menu == null) {
                        const applocation = []
                        res.apps.forEach(item => {
                            const apps = { code: '', name: '', active: '', menu: '' }
                            if (item.active) {
                                apps.code = item.code
                                apps.name = item.name
                                apps.active = item.active
                                apps.menu = res.menus
                                antDesignmenus = res.menus
                            } else {
                                apps.code = item.code
                                apps.name = item.name
                                apps.active = item.active
                                apps.menu = ''
                            }
                            applocation.push(apps)
                        })
                        Vue.ls.set(ALL_APPS_MENU, applocation, 7 * 24 * 60 * 60 * 1000)
                    } else {
                        antDesignmenus = res.menus
                    }
                    this.hasMenu = antDesignmenus.some(item => {
                        return item.path == this.$route.path
                    })
                    console.log(antDesignmenus, 'antDesignmenus', this.hasMenu, this.$route.path)
                    this.$store.dispatch('GenerateRoutes', { antDesignmenus }).then(() => {
                        // 动态添加可访问路由表user
                        // this.$router.addRoute(this.$store.state.route.addRouters) // addRoutes
                        // 如果当前路由菜单不存在， 跳转到第一个菜单
                    })
                })
                .catch(() => {
                    this.$store.dispatch('Logout').then(() => {
                        this.$router.push('/login') // , query: { redirect: to.fullPath }
                    })
                })
        },
        shipAlarm(msg) {
            const h = this.$createElement
            let alarmSceneName = msg.alarmSceneName || ''
            // const key = `open${Date.now()}`
            const key = `JHX`
            let areaName = ''
            switch (msg.areaType) {
                case '0':
                    areaName = '船舶'
                    break
                case '1':
                    areaName = '码头'
                    break
                case '2':
                    areaName = '区域'
                    break
                default:
                    break
            }
            this.$notification.open({
                message: h('div', { class: 'alarmName' }, `${msg.alarmTypeName}`),
                duration: 0,
                description: h('a-descriptions', { class: 'msg', props: { bordered: false, size: 'small' } }, [
                    h(
                        'a-descriptions-item',
                        {
                            props: {
                                label: '单位名称',
                                span: 3
                            }
                        },
                        `${msg.tenantName}`
                    ),
                    h(
                        'a-descriptions-item',
                        {
                            class: 'colorful',
                            props: {
                                label: '告警级别',
                                span: 3
                            }
                        },
                        `${msg.alarmLevelName}`
                    ),
                    h(
                        'a-descriptions-item',
                        {
                            props: {
                                label: '告警场景',
                                span: 3
                            }
                        },
                        `${alarmSceneName}`
                    ),
                    msg.areaName &&
                        h(
                            'a-descriptions-item',
                            {
                                props: {
                                    label: areaName,
                                    span: 3
                                }
                            },
                            `${msg.areaName}`
                        ),
                    h(
                        'a-descriptions-item',
                        {
                            props: {
                                label: '告警时间',
                                span: 3
                            }
                        },
                        `${msg.alarmTime}`
                    )
                ]),
                btn: h => {
                    return h(
                        'a-button',
                        {
                            style: {
                                background: '#1890FF',
                                color: '#fff',
                                border: 'none'
                            },
                            props: {
                                type: 'primary'
                            },
                            on: {
                                click: () => {
                                    this.$notification.close(key)
                                    this.$router.push('/shipAlarm/detail?id=' + msg.id + '&hide=1')
                                }
                            }
                        },
                        '去处理'
                    )
                },
                key,
                onClose: () => {}
            })
            //告警音频
            if (process.env.NODE_ENV === 'production') {
                this.$refs.alertAudioEl.play()
            }
        },
        Uint8ArrayToString(fileData) {
            var dataString = ''
            for (var i = 0; i < fileData.length; i++) {
                dataString += String.fromCharCode(fileData[i])
            }

            return dataString
        },
        setMenus() {
            const routes = convertRoutes(this.mainMenu.find(item => item.path === '/'))
            console.log('routes', routes)
            this.menus = (routes && routes.children) || []
            console.log(this.menus, 'this.menus')
        },
        toggle() {
            this.collapsed = !this.collapsed
            triggerWindowResizeEvent()
        },
        paddingCalc() {
            let left = ''
            if (this.sidebarOpened) {
                left = this.isDesktop() ? '202px' : '80px'
            } else {
                left = (this.isMobile() && '0') || (this.fixSidebar && '80px') || '0'
            }
            return left
        },
        drawerClose() {
            this.collapsed = false
        }
    }
}
</script>

<style lang="less" scoped>


.page-transition-enter {
    opacity: 0;
}

.page-transition-leave-active {
    opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
/deep/ .ant-descriptions-item-label {
    width: 65px;
}
/deep/ .ant-modal-confirm-body {
    text-align: center;
}

.mymsg {
    text-align: center;
}
</style>
